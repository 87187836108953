export const useFocusTrap = (element: HTMLElement) => {
  const focusableEls = element.querySelectorAll(
    'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])',
  )
  const firstFocusableEl = focusableEls[0] as HTMLElement
  const lastFocusableEl = focusableEls[focusableEls.length - 1] as HTMLElement
  const KEYCODE_TAB = 9

  const keydownHandler = (e: KeyboardEvent) => {
    const isTabPressed = e.key === 'Tab' || e.keyCode === KEYCODE_TAB

    if (!isTabPressed) return

    if (e.shiftKey && document.activeElement === firstFocusableEl) {
      lastFocusableEl.focus()
      e.preventDefault()
      return
    }

    if (e.shiftKey && document.activeElement === lastFocusableEl) {
      return
    }

    if (document.activeElement === lastFocusableEl) {
      firstFocusableEl.focus()
      e.preventDefault()
    }
  }

  return {
    activate: () => {
      element.focus()
      element.addEventListener('keydown', keydownHandler)
    },
    deactivate: () => element.removeEventListener('keydown', keydownHandler),
  }
}
