<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    show?: boolean
    placement: 'top' | 'bottom' | 'left' | 'right'
    heightClass?: string
    paddingClass?: string
    backgroundClass?: 'bg-primary' | string
    textClass?: 'text-primary' | string
    showClose?: boolean
    withBackdrop?: boolean
    title?: string
  }>(),
  {
    heightClass: 'h-full',
    paddingClass: 'p-4',
    backgroundClass: 'bg-alabaster',
    showClose: true,
    title: '',
    textClass: '',
  },
)

const emit = defineEmits(['update:show', 'close'])
const elRef = ref()

const placementClassMap = {
  top: {
    open: 'translate-y-0 top-0',
    closed: '-translate-y-[150%] top-0',
  },
  bottom: {
    open: 'translate-y-0 bottom-0',
    closed: 'translate-y-[150%] bottom-0',
  },
  left: {
    open: 'translate-x-0 left-0',
    closed: '-translate-x-full left-0',
  },
  right: {
    open: 'translate-x-0 right-0',
    closed: 'translate-x-full right-0',
  },
}

const dynamicClasses = computed(() => {
  const placementKey = props.show ? 'open' : 'closed'
  return [
    placementClassMap[props.placement][placementKey],
    props.heightClass,
    props.paddingClass,
    props.backgroundClass,
  ]
})

const clickOutside = () => {
  emit('close')
  emit('update:show', false)
}

onBeforeMount(() => document.addEventListener('click', clickOutside))
onUnmounted(() => document.removeEventListener('click', clickOutside))
onMounted(() => {
  const el = elRef.value as HTMLElement
  const useFocus = useFocusTrap(el)
  const useBackdrop = useModalBackdrop()
  const { activate, deactivate } = useBodyScroll()

  watch(
    () => props.show,
    (val) => {
      val && props.withBackdrop ? useBackdrop.activate() : useBackdrop.deactivate()
      val ? useFocus.activate() : useFocus.deactivate()
      val ? deactivate() : activate()
    },
  )
})
</script>

<template>
  <Teleport to="#modals">
    <div
      ref="elRef"
      v-bind="$attrs"
      role="alertdialog"
      class="drawer fixed z-40 transition duration-300 rounded-t-xl w-full ease-in-out shadow-top-md"
      :class="[dynamicClasses, { open: props.show }]"
      :aria-modal="props.show"
      :aria-hidden="!props.show"
      :tabindex="props.show ? undefined : '-1'"
    >
      <div
        v-if="props.showClose"
        class="flex items-center mb-4"
        :class="{ 'justify-end': !props.title, 'justify-between': props.title }"
      >
        <div
          v-if="props.title"
          class="text-lg font-bold"
          :class="props.textClass ?? 'text-gray-400'"
        >
          {{ props.title }}
        </div>

        <div class="w-[24px] h-[24px]">
          <RBaseButton
            color="none"
            class="drawer-close"
            :class="props.textClass ?? 'drawer-close'"
            @click="clickOutside"
          >
            <RIcon size="24" name="ic:round-close" />
          </RBaseButton>
        </div>
      </div>

      <div class="drawer-content h-full" @click.stop>
        <slot />
      </div>
    </div>
  </Teleport>
</template>

<style scoped>
.drawer-close {
  color: var(--color-primary);
}

.bg-primary {
  background-color: var(--color-primary);
}

.text-primary {
  color: var(--color-text);
}
</style>
