export const useBodyScroll = () => {
  const body = document.body

  const activate = () => {
    body.style.overflow = 'auto'
  }

  const deactivate = () => {
    body.style.overflow = 'hidden'
  }

  return {
    activate,
    deactivate,
  }
}
